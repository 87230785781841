const defaults = {
  confirm_icon: 'bi bi-exclamation-triangle',
  confirm_title: 'Confirm your action',
  confirm_commit: 'Confirm',
  confirm_cancel: 'Cancel',
};

const window_confirm = window.confirm;
$(document).on('confirm', ['a[data-confirm]', 'button[data-confirm]', 'input[type=submit][data-confirm]'].join(', '), function() {
  const e = window.event || window.confirm.caller.arguments[0];
  const el = e.target || e.srcElement; // the element's click that triggers confirm dialog
  if ($('#confirm-modal').length){    
    window.confirm = function () {
      return true;
    }
    return true;
  } else {
    let iconText = $(el).attr("data-confirm-icon");
    if (!iconText) {
      iconText = defaults.confirm_icon;
    }
    let titleText = $(el).attr("data-confirm-title");
    if (!titleText) {
      titleText = defaults.confirm_title;
    }
    let commitText = $(el).attr("data-confirm-commit");
    if (!commitText) {
      commitText = defaults.confirm_commit;
    }
    let cancelText = $(el).attr("data-confirm-cancel");
    if (!cancelText) {
      cancelText = defaults.confirm_cancel;
    }
    const confirmText = $(el).attr("data-confirm");

    const modal = $(
    '<div id="confirm-modal" class="fixed z-10 inset-0 overflow-y-auto">' + 
      '<div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">' + 
        '<div class="fixed inset-0 transition-opacity" aria-hidden="true">' + 
          '<div class="absolute inset-0 bg-gray-500 opacity-75"></div>' + 
        '</div>' + 
        '<span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>' + 
        '<div class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6" role="dialog" aria-modal="true" aria-labelledby="modal-headline">' + 
          '<div class="sm:flex sm:items-start">' + 
            '<div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">' + 
              '<i id="confirm-modal-icon" style="font-size: 1.2em;"></i>' + 
            '</div>' + 
            '<div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">' + 
              '<h3 id="confirm-modal-title" class="text-lg leading-6 font-medium text-gray-900" id="modal-headline"></h3>' +               
              '<div class="mt-2">' + 
                '<p id="confirm-modal-text" class="text-sm text-gray-500"></p>' + 
              '</div>' + 
            '</div>' + 
          '</div>' + 
          '<div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">' + 
            '<button id="confirm-modal-commit" type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm">' + 
            '<button id="confirm-modal-cancel" type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm">' +
          '</div>' + 
        '</div>' + 
      '</div>' + 
    '</div>'
    );
    $("body").append(modal);
    modal.find("#confirm-modal-icon").addClass(iconText);
    modal.find("#confirm-modal-title").text(titleText);
    modal.find("#confirm-modal-commit").text(commitText);
    modal.find("#confirm-modal-cancel").text(cancelText);
    modal.find("#confirm-modal-text").text(confirmText);

    modal.find('#confirm-modal-commit').on('click', function(){      
      $(el).get(0).click();
      $("#confirm-modal").remove();
      window.confirm = window_confirm;
    });
    modal.find('#confirm-modal-cancel').on('click', function(){
      $("#confirm-modal").remove();
    });
  }

  return false;
});